<template>
  <div>
    <template v-if="!editing">
      <eden-overview-card :title="title">
        <template slot="action">
          <el-button
            :type="'text'"
            :color="'primary'"
            :disabled="disableUpdate"
            @click="editing = true"
          >
            Edit Order
          </el-button>
        </template>
        <template slot="content">
          <el-collapse v-if="meals.length" accordion>
            <eden-collapse-item
              v-for="(meal, index) in meals"
              :key="index"
              :name="`${index}.${meal.combo}`"
              :activities="meal.activities"
              :wrap-bar="false"
            >
              <template slot="summary">
                <span class="type">
                  {{ formatMealName(meal.combo) }}
                  <el-tag
                    v-if="!meal.meal_in_menu"
                    :type="'danger'"
                    class="ml-1"
                    >Not In Menu</el-tag
                  >
                </span>
                <span class="status">{{
                  formatServiceActivity(order.last_marked_activity)
                }}</span>
              </template>
              <template slot="breakdown">
                <span class="font-sm text-grey-tertiary">
                  {{ meal.spName }}
                </span>
              </template>
            </eden-collapse-item>
          </el-collapse>
          <div v-else class="is-flex w-100 is-justify-center">
            <p class="font-sm">No meals</p>
          </div>
        </template>
      </eden-overview-card>
      <order-address-information
        :type="'delivery'"
        :order="orderDeliveryInformation"
        :deleted-status="deleted"
      />
    </template>
    <template v-else class="fadeIn">
      <order-meals-edit
        :order-id="orderId"
        :service="service"
        :menu="menu"
        :meals="meals"
        :order-delivery-date="delivery"
        :customer-id="customerId"
        @toggle-edit="toggleEdit"
        @get-order="$emit('get-order')"
      />
    </template>
  </div>
</template>

<script>
import OrderMealsEdit from "./OrderMealEdit";
import OrderAddressInformation from "@/components/Orders/Order/Address/OrderAddressInformation";
import {
  mealIndex,
  mealActivities,
  mealsInMenu,
  mealsInOneTimeMenu,
} from "./meal.config";

import meals from "@/requests/orders/meals";

export default {
  name: "OrderMealItems",
  props: {
    order: {
      type: Object,
      default() {
        return [];
      },
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: { OrderMealsEdit, OrderAddressInformation },
  data() {
    return {
      editing: false,
      menu: [],
      meals: [],
    };
  },
  computed: {
    deleted() {
      return this.order.customer_email === null ? true : false;
    },
    title() {
      return `Order Items (${this.meals.length})`;
    },
    orderId() {
      return this.order.order_id;
    },
    service() {
      return this.order.service.toLowerCase();
    },
    delivery() {
      return this.order.deliver_to_customer_date;
    },
    customerId() {
      return this.order.customer_id;
    },
    mealServicePartners() {
      return this.$store.getters.service_partners_list.meal;
    },
    orderDeliveryInformation() {
      return {
        order_id: this.order.order_id,
        delivery_date: this.order.deliver_to_customer_date,
        location_area: this.order.location_area,
        address: this.order.home_address,
        phone_number: this.order.phone_number,
      };
    },
  },
  created() {
    if (this.order.is_one_time_order === true && !this.order.is_complementary) {
      this.getOneTimeMenu();
    } else {
      this.getMenu();
    }
  },
  methods: {
    getMenu() {
      this.loading = true;
      meals
        .menu(this.order.deliver_to_customer_date, this.customerId)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.menu = mealsInMenu(data);
            this.setMeals();
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getOneTimeMenu() {
      this.loading = true;
      meals
        .menuOneTime()
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.menu = mealsInOneTimeMenu(data);
            this.setMeals();
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setMeals() {
      const processMealItem = (combo, serviceProvider) => {
        const mealName = combo.meal_combo
          ? combo.meal_combo.toLowerCase()
          : null;
        const specificItem = this.order.extra_details.find(
          (item) => item.specifics.toLowerCase() === mealName,
        );
        const mealSp =
          serviceProvider ||
          (specificItem ? specificItem.service_provider_id : null);
        const mealSpName = this.mealServicePartners[mealSp]
          ? this.mealServicePartners[mealSp].name
          : "";
        const mealInMenuWithoutSauce = this.menu.find((meal) =>
          meal.name === mealName?.includes("pepper sauce")
            ? mealName.slice(0, mealName.indexOf("pepper sauce") - 3)
            : mealName,
        );
        if (
          mealName &&
          mealName.includes("pepper sauce") &&
          mealInMenuWithoutSauce
        ) {
          this.menu.push({
            name: mealName,
            // Add calorie logic if you have data related to additional combos
          });
        }
        const mealInMenu = this.menu.find((meal) => meal.name === mealName);
        if (mealName) {
          let order = this.parseData(this.order);
          order.extra_details = {
            is_packed: order.is_packed,
            is_packed_time: order.is_packed_time,
            is_dispatched: order.is_dispatched,
            is_dispatched_time: order.is_dispatched_time,
            is_delivered: order.is_delivered,
            is_delivered_time: order.is_delivered_time,
          };
          this.meals.push({
            combo: mealName,
            sp: parseInt(mealSp),
            spName: mealSpName,
            pepper_sauce: mealName.includes("pepper sauce"),
            activities: mealActivities(order, mealName, mealSpName),
            meal_in_menu: !!mealInMenu,
          });
        }
      };

      mealIndex().forEach((item) => {
        const mealItem = this.order[`${item}_combo`];
        const mealSp = this.order[`sp_${item}_combo`];
        // const mealSp = this.order[`sp_${item}_main`]
        //   ? this.order[`sp_${item}_main`]
        //   : this.order[`sp_${item}_combo`];
        processMealItem({ meal_combo: mealItem }, mealSp);
      });

      if (this.order.extra_details.length > 20) {
        const additionalCombos = JSON.parse(this.order.additional_meal_combos);
        additionalCombos.forEach((comboItem) => {
          const mealSp = comboItem.service_provider_id;
          processMealItem(comboItem, mealSp);
        });
      }
      this.sortArray({ data: this.menu, property: "name" });
      this.loading = false;
    },

    // setMeals() {
    //   mealIndex().forEach((item) => {
    //     console.log(this.order, "SET ITEM")
    //     const mealItem = this.order[`${item}_combo`];
    //     const mealName = mealItem ? mealItem.toLowerCase() : null;
    //     const mealCalorie = this.order[`${item}_combo_calorie`] || "";
    //     const mealSp = this.order[`sp_${item}_combo`] || null;
    //     console.log(mealSp)
    //     const mealSpName = this.mealServicePartners[mealSp]
    //       ? this.mealServicePartners[mealSp].name
    //       : "";
    //     const mealInMenuWithoutSauce = this.menu.find((meal) =>
    //       meal.name === mealName?.includes("pepper sauce")
    //         ? mealName.slice(0, mealName.indexOf("pepper sauce") - 3)
    //         : mealName,
    //     );
    //     if (
    //       mealName &&
    //       mealName.includes("pepper sauce") &&
    //       mealInMenuWithoutSauce
    //     ) {
    //       this.menu.push({
    //         name: mealName,
    //         calorie: mealCalorie,
    //       });
    //     }
    //     const mealInMenu = this.menu.find((meal) => meal.name === mealName);
    //     if (mealItem) {
    //       let order = this.parseData(this.order);
    //       order.extra_details = {
    //         is_packed: order.is_packed,
    //         is_packed_time: order.is_packed_time,
    //         is_dispatched: order.is_dispatched,
    //         is_dispatched_time: order.is_dispatched_time,
    //         is_delivered: order.is_delivered,
    //         is_delivered_time: order.is_delivered_time,
    //       };
    //       this.meals.push({
    //         combo: mealName,
    //         calorie: mealCalorie,
    //         sp: parseInt(mealSp),
    //         spName: mealSpName,
    //         pepper_sauce: mealName.includes("pepper sauce"),
    //         activities: mealActivities(order, mealItem, mealSpName),
    //         meal_in_menu: !!mealInMenu,
    //       });
    //     }

    //     this.sortArray({ data: this.menu, property: "name" });
    //     this.loading = false;
    //   });
    // },
    toggleEdit() {
      this.$emit("getOrder");
      this.editing = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
