<template>
  <div>
    <eden-overview-card :title="title">
      <template slot="action">
        <el-button
          :type="'text'"
          :color="'primary'"
          :disabled="disableUpdate"
          @click="editInformation = true"
        >
          Edit
          {{ formatText(type) }} Information
        </el-button>
      </template>
      <template slot="content">
        <div class="info-item">
          <span class="label">Address</span>
          <span class="content">{{ information.address || "-" }}</span>
        </div>
        <div class="info-item">
          <span class="label">Location Area</span>
          <span class="content">{{ information.location_area || "-" }}</span>
        </div>
        <div class="info-item">
          <span class="label">Phone Number</span>
          <span class="content">{{
            formatPhoneNumber(information.phone_number)
          }}</span>
        </div>
      </template>
    </eden-overview-card>
    <order-address-information-edit
      :type="type"
      :service="service"
      :show.sync="editInformation"
      :information="information"
      @updated="getInformation"
    />
  </div>
</template>

<script>
import OrderAddressInformationEdit from "./OrderAddressInformationEdit.vue";
import order from "@/requests/orders/order";

export default {
  name: "OrderAddressInformation",
  props: {
    type: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    deletedStatus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderAddressInformationEdit,
  },
  data() {
    return {
      editInformation: false,
      information: {
        default: true,
        address: "",
        location_area: null,
        phone_number: "",
      },
    };
  },
  computed: {
    title() {
      return `${this.formatText(this.type)} Information`;
    },
    disableUpdate() {
      if (this.deletedStatus) {
        return true;
      }
      const roles = ["admin", "operations", "gardener", "gardener_pro"];
      if (!this.allowAccessFor(roles.concat("gardener", "gardener_pro"))) {
        return true;
      }

      if (this.allowAccessFor(["admin"])) {
        return false;
      }

      const currentTime = this.formatTime("now", "24h");

      const currentDate = this.formatDate(new Date(), "y-m-d");
      const pickupDate =
        this.type === "pickup"
          ? this.formatDate(this.order.pickup_date, "y-m-d")
          : null;
      const deliveryDate = this.formatDate(this.order.delivery_date, "y-m-d");

      const daysToDelivery = this.getDateDifference({
        fDate: pickupDate ? pickupDate : deliveryDate,
        sDate: currentDate,
        unit: "day",
      });

      if (daysToDelivery > 1) {
        return false;
      }

      if (daysToDelivery === 1) {
        if (this.allowAccessFor(["operations"])) {
          return false;
        }

        if (currentTime >= "17:30") {
          return true;
        }
      }

      return daysToDelivery < 1;
    },
  },
  created() {
    this.getInformation();
  },
  methods: {
    getInformation() {
      order
        .addressInfoGet(this.order.order_id, this.type)
        .then((response) => {
          const { status, data } = response;

          if (status) {
            data.data.length
              ? this.setUpdatedInformation(data.data[0])
              : this.setDefaultInformation();
          } else {
            this.$message.error(response.data.message)
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setUpdatedInformation(data) {
      this.information = {
        default: false,
        delivery_info_id: data.id,
        order_id: this.order.order_id,
        location_area: data.location_area.name,
        address: data.address,
        phone_number: data.phone_number,
      };
    },
    setDefaultInformation() {
      this.information = {
        default: true,
        order_id: this.order.order_id,
        location_area: this.order.location_area,
        address: this.order.address,
        phone_number: this.order.phone_number,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.info-item {
  margin-bottom: 12px;
  padding: 12px;
  border: 1px solid #f0f4f2;
  border-radius: 8px;
  font-size: 0.775rem;

  .label {
    margin-bottom: 4px;
    display: block;
    color: var(--eden-grey-tertiary);
  }

  .content {
    color: var(--eden-grey-secondary);
  }
}
</style>
