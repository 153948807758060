import axios from "axios";

export default {
  schedule(servicePartnerId, period, page) {
    if (servicePartnerId === "all") {
      return axios.get(`customer_orders/zones?period=${period}`);
    }
    return axios.get(
      `${servicePartnerId}/customer_orders/zones?page=${page}&period=${period}`,
    );
  },

  search(servicePartnerId, query, period) {
    if (servicePartnerId === "all") {
      return `stewards/customer_orders/zones/no_sp/search/${query}?period=${period}`;
    }
    return axios.get(
      `stewards/customer_orders/zones/${servicePartnerId}/search/${query}?period=${period}`,
    );
  },

  export(servicePartnerId, period) {
    if (servicePartnerId === "all") {
      return `customer_orders/for_stewards/zones/export?period=${period}`;
    }
    return axios.get(
      `${servicePartnerId}/customer_orders/for_stewards/zones/export?period=${period}`,
    );
  },

  menu(date, userId = "") {
    const params = userId ? `&user_id=${userId}` : "";
    return axios.get(`meal/items/all?delivery_date=${date}${params}`);
  },

  menuOneTime() {
    return axios.get(`onetime_order/menu/active`);
  },
};
