<template>
  <div class="edit-order" v-loading="loading">
    <eden-overview-card :title="title">
      <template slot="action">
        <div class="edit-order__actions">
          <el-button plain @click="undoChanges">Undo Changes </el-button>
          <el-button type="primary" :loading="updating" @click="saveChanges">
            Save Changes
          </el-button>
        </div>
      </template>
      <template slot="content">
        <div class="edit-order__banner" @click="$emit('toggle-edit')">
          <span class="font-sm">
            You are now editing this order.
            <span class="text-primary">
              Click here to exit without saving changes.
            </span>
          </span>
        </div>
        <div class="edit-order__content">
          <el-form :model="form" label-position="top" ref="form">
            <div
              v-for="(meal, index) in form.meals"
              :key="index"
              class="edit-order__item fadeIn"
            >
              <div class="title">
                <p>Meal {{ formatMealIndex(index) }}</p>
                <div class="is-flex is-align-center">
                  <el-tag v-if="!meal.meal_in_menu" :type="'danger'"
                    >Not in Menu</el-tag
                  >
                  <div v-if="meal.calorie" class="eden-calorie">
                    <span>{{ meal.calorie }}</span>
                    <span> kcal</span>
                  </div>
                </div>
              </div>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Meal"
                    :prop="'meals.' + index + '.combo'"
                    :rules="rules.meal"
                  >
                    <el-select
                      v-model="meal.combo"
                      filterable
                      @change="setMealItem(index)"
                    >
                      <el-option
                        v-for="(item, index) in mealServiceTypes"
                        :key="index"
                        :label="formatMealName(item.name)"
                        :value="item.name"
                      >
                        <span style="float: left">{{
                          formatMealName(item.name)
                        }}</span>
                        <span
                          v-if="item.calorie"
                          style="
                            float: right;
                            font-weight: 600;
                            font-size: 13px;
                          "
                          >{{ item.calorie }} kcal</span
                        >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item label="">
                    <el-switch
                      v-model="meal.pepper_sauce"
                      :active-value="true"
                      :active-text="
                        location == 'KE'
                          ? 'Add Eden Chilli Oil'
                          : 'Add Pepper Sauce'
                      "
                      :inactive-value="false"
                      @change="togglePepperSauce(index)"
                    >
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Service Partner"
                    :prop="'meals.' + index + '.sp'"
                    :rules="{
                      required: true,
                      message: 'Service Partner is required',
                      trigger: 'change',
                    }"
                  >
                    <el-select
                      v-model.number="meal.sp"
                      placeholder="Select Service Partner"
                      filterable
                    >
                      <el-option
                        v-for="sp in mealServicePartners"
                        :key="sp.id"
                        :label="sp.name"
                        :value="sp.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24" class="is-flex justify-center">
                  <el-button
                    :type="'text'"
                    :class="'danger'"
                    :icon="'eden-icon-delete'"
                    @click="deleteMeal(index)"
                  >
                    Delete this meal
                  </el-button>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="24" class="is-flex justify-center">
                <el-button
                  :type="'text'"
                  :icon="'eden-icon-add-circle'"
                  @click="addMeal"
                  >Add another meal
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import { mealIndex } from "@/components/Orders/Order/Meal/meal.config";
import order from "@/requests/orders/order";

export default {
  name: "OrderMealsEdit",
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    service: {
      type: String,
      default: null,
    },
    orderDeliveryDate: {
      type: String,
      default: "",
    },
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
    meals: {
      type: Array,
      default() {
        return [];
      },
    },
    customerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    const validateMeal = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("You must select a meal."));
      } else {
        const meal = this.menu.find(
          (meal) => meal.name.toLowerCase() === value.toLowerCase(),
        );
        meal ? callback() : callback(new Error("Not in this week’s Menu."));
      }
    };
    return {
      loading: false,
      mealIndex: mealIndex(),
      form: {
        meals: [],
      },
      rules: {
        meal: [{ validator: validateMeal, trigger: "change" }],
      },
      updating: false,
    };
  },
  computed: {
    title() {
      return `Edit Order (${this.form.meals.length})`;
    },
    mealServiceTypes() {
      return this.sortList(this.menu, "name");
    },
    mealServicePartners() {
      return this.$store.getters.service_partners_list.meal;
    },
    location() {
      return this.$store.getters.location;
    },
  },
  created() {
    this.setFormData();
  },
  methods: {
    setFormData() {
      this.form.meals = this.parseData(this.meals);
    },
    addMeal() {
      const meals = this.form.meals;

      if (meals.length < 20) {
        this.form.meals.push({
          combo: "",
          calorie: "",
          sp: null,
          pepper_sauce: false,
          meal_in_menu: true,
        });
      } else {
        this.$message.error("Maximum amount of meals added");
      }
    },
    setMealItem(index) {
      this.form.meals[index].meal_in_menu = true;
      this.setPepperSauce(index);
      this.setCalorieCount(index);
    },
    setPepperSauce(index) {
      let meal = this.form.meals[index];
      const sauce =
        this.location === "KE" ? " eden chilli oil" : " pepper sauce";
      meal.pepper_sauce = meal.combo.includes(sauce);
    },
    setCalorieCount(index) {
      const mealName = this.form.meals[index].combo;
      const meal = this.menu.filter((meal) => meal.name === mealName);
      this.form.meals[index].calorie = meal.length ? meal[0].calorie : "";
    },
    togglePepperSauce(index) {
      const sauce =
        this.location === "KE" ? " eden chilli oil" : " pepper sauce";
      let meal = this.form.meals[index];
      if (meal.pepper_sauce) {
        const mealName = `${meal.combo}, ${sauce}`;
        const mealInMenu = this.menu.find((meal) => meal.name === mealName);
        if (!mealInMenu) {
          this.menu.push({
            name: mealName,
            calorie: "",
          });
          this.sortArray({ data: this.menu, property: "name" });
        }
        setTimeout(() => {
          meal.combo = mealName;
        }, 100);
      } else {
        meal.combo = meal.combo.replace(`, ${sauce}`, "");
      }
    },
    deleteMeal(index) {
      this.form.meals.splice(index, 1);
    },
    formattedMealItems() {
      let meals = {};
      let spPayload = [];

      this.form.meals.forEach((meal, index) => {
        console.log(meal, "NNNNNNNoooo");
        meals[`${this.mealIndex[index]}_combo`] = meal.combo.toLowerCase();
        meals[`sp_${this.mealIndex[index]}_combo`] = meal.sp;

        spPayload.push({
          serviceProviderID: meal.sp,
          specifics: meal.combo,
        });
      });

      return {
        meals,
        spSpecifics: { sp_specifics: JSON.stringify(spPayload) },
      };
    },
    undoChanges() {
      this.setFormData();
    },
    saveChanges() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;

        const mealItemSpecifics = this.formattedMealItems();

        const payload = {
          id: this.orderId,
          service: this.service.includes("daily") ? "dailymeals" : "meal",
          orderPayload: {
            ...mealItemSpecifics.meals,
            customerId: this.customerId,
            customer_is_beta: true,
          },
          spPayload: mealItemSpecifics.spSpecifics,
        };
        console.log(payload, "save changes payload");

        order
          .update(payload)
          .then((response) => {
            const orderUpdate = response[0].data;
            const spUpdate = response[1].data;
            if (orderUpdate.status && spUpdate.status) {
              this.$message.success("Order details updated successfully");
            }
            this.updating = false;
            this.$emit("toggle-edit");
            this.$emit("get-order");
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-order {
  &__content {
    max-height: 500px;
    overflow-y: scroll;
  }

  &__item {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      p {
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }
}

.eden-calorie {
  margin-left: 10px;
  border-radius: 4px;
  background: var(--eden-grey-primary);

  span {
    padding: 5px 7px;
    font-size: 0.875rem;
    font-weight: 600;
    color: #fff;
  }

  span:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: var(--eden-grey-primary);
  }

  span:last-child {
    padding-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: var(--eden-green-primary);
  }
}
</style>
