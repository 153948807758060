<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    width="28%"
    @close="closeEvent"
  >
    <el-form :model="form" label-position="top" ref="form" v-loading="loading">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Address" prop="address" :rules="validateField()">
            <el-input
              type="textarea"
              rows="3"
              v-model="form.address"
              :disabled="service === 'cleaning'"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Location area"
            prop="location_area_id"
            :rules="validateField()"
          >
            <el-select
              v-model="form.location_area_id"
              filterable
              placeholder="Select location"
              :disabled="service === 'cleaning'"
            >
              <el-option
                v-for="location in locationareas"
                :key="location.id"
                :label="location.name"
                :value="location.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Phone number" :rules="validatePhone()">
            <eden-phone-input :input.sync="form.phone_number" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :loading="saving" @click="verifyInformation"
        >Save Changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import order from "@/requests/orders/order";
import * as actions from "@/store/action-types";

export default {
  name: "OrderAddressInformationEdit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      default: "",
    },
    information: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        address: "",
        location_area_id: "",
        phone_number: null,
      },
      saving: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `Edit ${this.formatText(this.type)} Information`;
    },
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    show() {
      if (this.show) {
        const { address, phone_number } = this.information;
        this.form.address = address;
        this.form.phone_number = phone_number;

        if (!this.locationareas.length) {
          this.getLocationAreas();
        } else {
          this.form.location_area_id = this.getLocationAreaId();
        }
      }
    },
  },
  methods: {
    getLocationAreas() {
      this.loading = true;
      this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST).then(() => {
        this.form.location_area_id = this.getLocationAreaId();
        this.loading = false;
      });
    },
    getLocationAreaId() {
      const locationareas = this.locationareas.filter(
        (area) => area.name === this.information.location_area
      );
      if (locationareas.length) {
        return locationareas[0].id;
      }
    },
    verifyInformation() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.saving = true;
        this.information.default
          ? this.saveInformation()
          : this.updateInformation();
      });
    },
    saveInformation() {
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_id: this.information.order_id,
        address_type: this.type,
        ...this.form,
      };
      order
        .addressInfoAdd(payload)
        .then((response) => {
          const { status, message } = response;
          if (status) {
            this.success();
          } else {
            this.$message.error(message);
            this.saving = false;
          }
        })
        .catch((error) => {
          this.saving = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateInformation() {
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        delivery_info_id: this.information.delivery_info_id,
        actor_id: logged_in_userId,
        ...this.form,
      };
      order
        .addressInfoUpdate(payload)
        .then((response) => {
          const { status, message } = response;
          if (status) {
            this.success();
          } else {
            this.$message.error(message);
            this.saving = false;
          }
        })
        .catch(() => {
          this.saving = false;
        });
    },
    success() {
      this.$message.success(
        `${this.formatText(this.type)} Information updated successfully.`
      );
      this.$emit("updated");
      this.closeEvent();
    },
    closeEvent() {
      this.setShow = false;
      this.saving = false;
      this.$refs.form.resetFields();
    },
  },
};
</script>
